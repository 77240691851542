
import {renderUnit} from "@/utils/common"
export default {
    data() {
        return {
            //url: `/admin/express_invoice/reviews/${this.$route.query.id}`,
            where: {},
            selection: []
        };
    },
    computed: {
        url() {
            return `/admin/express_invoice/reviews/${this.$route.query.id}`;
        },
        columns() {
            return [{
                    title: this.$t("客户代码"),
                    dataIndex: "customer_code",
                    sorter: true,
                    width: 120
                },
                {
                    title: this.$t("主单号"),
                    dataIndex: "master_tracking_number",
                    sorter: true,
                    width: 200
                },
                {
                    title: this.$t("分单号"),
                    dataIndex: "tracking_number",
                    sorter: true,
                    width: 200
                },
                {
                    title: this.$t("系统尺寸/重量"),
                    sorter: true,
                    width: 220,
                    customRender({
                        record
                    }) {
                        return `${record.package_length} x ${record.package_width} x ${record.package_height} ${this.renderUnit(record, 'dims_unit')} - ${record.package_weight || 0} ${this.renderUnit(record, 'weight_unit')}`;
                    }
                },
                {
                    title: this.$t("包裹费用"),
                    dataIndex: "package_charge",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t("服务费"),
                    dataIndex: "service_charge",
                    sorter: true,
                    width: 120
                },
                {
                    title: this.$t("账单尺寸/重量"),
                    sorter: true,
                    width: 220,
                    customRender({
                        record
                    }) {
                        return `${record.dim_length} x ${record.dim_width} x ${record.dim_height} ${this.renderUnit(record, 'dims_unit')} - ${record.dim_weight || 0} ${this.renderUnit(record, 'weight_unit')}`;
                    }
                },
                {
                    title: this.$t("账单费用"),
                    dataIndex: "invoice_charge",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t("差值"),
                    dataIndex: "diff",
                    sorter: true,
                    width: 100
                },
                {
                    key: "action",
                    title: this.$t("操作"),
                    slots: {
                        customRender: "action",
                    }
                }
            ]
        }
    },
    mounted() {
        this.$store.dispatch('theme/tabSetTitle', {
            fullPath: this.$route.fullPath,
            title: `${this.$t('对账')} (${this.$route.query.number})`
        });
    },
    methods: {
        /**
         * 刷新表格
         */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /**
         * 重置搜索
         */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 推送账单
         */
        pushAll(rows) {
            this.$http.post(`/admin/express_invoice/push/${rows[0].invoice_id}`, {
                ids: rows.map(a => a.package_id)
            }).then(res => {
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        }
    },
    setup(){
        return {
            renderUnit
        }
    }
};
